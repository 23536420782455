.employee {
  // height: calc(100vh - 110px);
  min-height: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  .ant-tabs-content-holder {
    padding-left: 10px;
    padding-right: 10px;
  }
  .no-record-documents {
    right: 50%;
    transform: translateX(50%);
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: end;
    img {
      max-width: 100px;
    }
  }
}

.employee-proposal-btn {
  font-size: 14px;
}

@media (max-width: 1680px) {
  .employee-proposal-btn {
    font-size: 12px;
  }
}

@media (max-width: 1550px) {
  .employee-proposal-btn {
    font-size: 11px;
  }
}
@media (max-width: 1300px) {
  .employee-proposal-btn {
    font-size: 11px;
  }
}

@media (max-width: 1024px) {
  .employee .ant-tabs-content-holder {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
