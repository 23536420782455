.salary {
  &_prticulars {
    &-container {
      ul {
        li {
          color: #333333;
          margin-bottom: 25px;
          font-family: "Rubik", sans-serif;
        }
      }
      ._header {
        color: #d34a7c;
        font-weight: 500;
        margin-bottom: 25px;
        font-family: "Rubik", sans-serif;
      }
      ._monthly,
      ._annual {
        color: #595959;
        font-weight: 500;
        margin-bottom: 25px;
        font-family: "Rubik", sans-serif;
      }
      .salary-gross {
        color: #333333;
        font-weight: 700;
        font-family: "Rubik", sans-serif;
      }
    }
  }
}

@media (max-width: 768px) {
  .salary_prticulars-container .monthly_details ul {
    margin-top: 48px;
    font-size: 13px !important;
  }
  .salary_prticulars-container .monthly_details ul li {
    height: 40px;
    font-size: 13px !important;
  }
  .salary_prticulars-container .annual_details ul {
    margin-top: 48px;
    font-size: 13px !important;
  }
  .salary_prticulars-container .annual_details ul li {
    height: 40px;
    font-size: 13px !important;
  }
  .salary_prticulars-container .particulars ul li {
    height: 40px;
    font-size: 13px !important;
  }

  .salary_prticulars-container .salary-gross {
    font-weight: 400 !important;
    font-size: 13px !important;
  }
  .salary_prticulars-container .ant-tabs {
    font-size: 13px !important;
  }
}
