.search-field {
  min-width: 300px;
  input {
    padding: 12px 15px 12px 15px;
    background-color: #f6f6f6 !important;
    border-radius: 30px;
    height: 40px;
    box-shadow: unset !important ;
    border: none;
    &:focus {
      border: none;
    }
  }
  .ant-input-group-addon {
    padding: 2px 15px 2px 4px;
    background-color: #f6f6f6;
    border-radius: 0px 20px 20px 0px;
    button {
      padding: 4px 10px 1px 4px;

      background-color: transparent !important;
    }
  }
  .ant-btn-icon {
    svg {
      fill: #595959;
    }
  }
}
// .ant-table-pagination {
//     display: none !important;
// }
.ant-pagination-options {
  order: -1;
}

.common-table {
  max-height: 75vh;
  overflow-y: auto;
  height: 54vh;
  thead {
    position: sticky;
    top: 0;
    z-index: 8;
    height: 60px;
    th {
      background-color: #fff !important;
    }
  }
  .ant-table-content {
    padding: 10px 20px;
  }
}
.organization__Tab {
  .ant-table-content {
    height: calc(60vh - 40px);
    padding-right: 6px;
  }
}
.ant-dropdown-trigger {
  display: block;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}
#departmentName,
#email,
#description,
#password {
  border-bottom: 1px solid #c4c4c4;
}

.ant-input-wrapper:hover {
  border-radius: 30px;
  box-shadow: 0 0 0 2px rgba(255, 5, 105, 0.06) !important;
}
