$sickLeaveBg: #F0572626;
$casualLeaveBg: #FFF9E4;
$earnedLeaveBg: #F5F6FF;
$permissionLeaveBg: #cff6ff;

$sickLeaveText: #D34A7C;
$casualLeaveText: #E3AD3F;
$earnedLeaveText: #275094;
$permissionText: #0e96b0;

.sickLeaveBg {
  background-color: $sickLeaveBg;
}

.casualLeaveBg {
  background-color: $casualLeaveBg;
}

.earnedLeaveBg {
  background-color: $earnedLeaveBg;
}

.permissionLeaveBg {
  background-color: $permissionLeaveBg;
}

.sickLeaveText {
  color: $sickLeaveText;
}

.casualLeaveText {
  color: $casualLeaveText;
}

.earnedLeaveText {
  color: $earnedLeaveText;
}

.permissionLeaveText {
  color: $permissionText;
}

.swiper-wrapper
{
  padding-bottom: 2rem;
}
.swiper-pagination-bullet-active
{
  background: #d34a7c !important;
}