.employee-chart_shadow {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  top: 58px;
  left: 97px;
  box-shadow: 0px 0px 8px rgba(127, 21, 60, 0.2);
}
.apex-chart {
  width: 300px;
}
@media (max-width: 1800px) {
  .chart_doughnut {
    width: 58%;
  }
}
@media (max-width: 1620px) {
  .apex-chart {
    width: 260px;
  }
  .employee-chart_shadow {
    width: 103px;
    height: 103px;
    top: 45px;
    left: 79px;
  }
  .chart_doughnut {
    width: 58%;
  }
}
@media (max-width: 1380px) {
  //   .apex-chart {
  //     width: 210px;
  //   }
  //   .employee-chart_shadow {
  //     width: 70px;
  //     height: 70px;
  //     top: 26%;
  //     left: 34%;
  //   }
  .dashboard_chart {
    padding-left: 0 !important;
  }
  .chart_list {
    margin-left: 15px;
    &-span {
      @apply mr-2;
    }
    &-count {
      @apply mr-2;
    }
  }
  .apexcharts {
    &-text {
      font-size: 15px;
    }
    &-datalabel-label {
      font-size: 12px;
    }
  }
}
.employee-chart {
  &-count {
    font-size: 18px;
    color: #334a51;
    font-weight: 500;
  }
  &_text {
    color: #707070;
    font-size: 12px;
  }
}
// employee chart list items
.chart_list {
  &-item {
    color: #333333;
    font-size: 1rem;
  }
  &-span {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: inline-block;
  }
  &-count {
    color: #d34a7c;
    font-size: 14px;
  }
  $priorities: (
    "confirmed": #2c5ba7,
    "appraisal": #0ebde0,
    "probation": #efbf03,
    "archived": #f05726,
  );
  @each $name, $value in $priorities {
    .employee-#{$name} {
      background-color: $value;
    }
  }
}
.apexcharts-datalabels {
  text {
    font-size: 13px;
  }
}
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value,
.apexcharts-datalabels,
.apexcharts-pie-label {
  font-family: "Rubik", sans-serif !important;
}
