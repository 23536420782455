@import "./assets/styles/main.scss";
@import "./assets/styles/variable";

.login-form {
  .login-forgot-password {
    a {
      color: #2c5ba7;
    }
  }
  .ant-spin-spinning {
    top: 0px !important;
    height: 100% !important;
  }
}
form {
  input {
    width: 100%;
    border-bottom: 1px solid #2c5ba7;
  }
}
.login-left-content {
  .login-img {
    max-width: 600px;
    width: 600px;
  }
  .unique-platform-text {
    color: #8a8da2;
    font-size: 21px;
  }
}

/* Media Query for Responsiveness */
@media (max-width: 768px) {
  .login-box {
    width: 80%;
  }
  .login-left-content .login-img {
    width: 40vh;
    height: 40vh;
    max-width: 100%;
  }
  .backgroundImage {
    background-image: none;
    overflow: auto;
    height: 100vh;
  }
  .google-lo-btn {
    width: 250px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 400px) and (max-width: 1024px) {
  .login-left-content .unique-platform-text {
    font-size: 23px;
  }
}
/* Additional Styles for Error Messages, Success Messages, etc. can be added as needed */
