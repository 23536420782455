.ql {
  font-size: 10px;
  &-container {
    height: 45vh;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  &-clipboard {
    display: none;
  }
  &-tooltip {
    display: none;
  }
  &-header {
    display: none;
  }
  &-editor {
    height: 100%;
    outline: none;
    overflow-y: auto;

    p {
      font-size: 14px;
    }
  }
}
.ant-modal-header {
  text-align: center;
}
.ql-container.ql-snow {
  width: 100%;
  max-height: 200px;
}

.ql-editor {
  padding-bottom: 44px;
}
