.review-modal {
  font-family: "Rubik", sans-serif;
  svg {
    fill: rgba(0, 0, 0, 0.55);
  }
  .text-area {
    .appraisal-form {
      justify-content: start !important;
    }
  }
  height: 86vh;
  overflow-y: auto;
  &.email-template {
    height: auto;
  }
  .float-control {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 4px 4px 0 0;
    padding: 0.75em 0.75em 0 0.75em;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding-left: 10px !important;
  }
  .ant-select {
    max-width: 200px;
    width: 100% !important;
    &-selector {
      border: 1px solid #d9d9d9 !important;
      max-width: 200px;
      width: 100%;
      box-shadow: none;
    }
  }

  .general {
    &-assessment {
      .appraisal-form {
        display: flex;
        justify-content: end;
        padding-right: 37px;
        font-family: "Rubik", sans-serif;
      }
      textarea {
        font-family: "Rubik", sans-serif;
        border: 0;
        border-bottom: 1px solid #595959 !important;
        border-radius: 0;
        background: #f5f5f5;
      }
      &-header {
        color: #d34a7c;
        font-size: 14px;
        font-weight: 500;
        min-height: 20px;
        &.exitForm {
          color: #2d2d2d;
        }
      }
      &-subtitle {
        font-size: 15px;
        font-weight: 600;
      }
      .ant {
        &-collapse {
          border-radius: 3px !important;
          &-header {
            display: flex;
            align-items: center;
          }
        }
        &-select {
          &-selector {
            background-color: rgba(0, 0, 0, 0.02);
            border: 0 !important;
            border-bottom: 1px solid rgba(89, 89, 89, 0.21) !important;
            border-radius: 0;
            font-family: "Rubik", sans-serif;
          }
        }
      }
      .key-questions {
        .form-item {
          width: 200px;
          display: contents;
        }
      }
    }
  }
}
.appraisal-increment {
  font-family: "Rubik", sans-serif;
  textarea {
    border: 0;
    border-bottom: 1px solid #595959 !important;
    border-radius: 0;
    background: #f5f5f5;
    font-family: "Rubik", sans-serif;
  }
  &-header {
    color: #d34a7c;
    font-size: 14px;
    font-weight: 500;
  }
  &-subtitle {
    font-size: 15px;
    font-weight: 600;
  }
  .ant {
    font-family: "Rubik", sans-serif;
    &-collapse {
      border-radius: 3px !important;
      &-header {
        display: flex;
        align-items: center;
      }
    }
    &-select {
      &-selector {
        background-color: rgba(0, 0, 0, 0.02);
        border: 0 !important;
        border-bottom: 1px solid rgba(89, 89, 89, 0.21) !important;
        border-radius: 0;
      }
    }
  }
  .key-questions {
    .form-item {
      width: 200px;
      display: contents;
    }
  }
  .label {
    top: -6px !important;
  }
}

.ant-collapse-header {
  border-radius: unset;
}
.anticon-plus,
.anticon-minus {
  color: #cd3c71 !important;
}

.control-float-label {
  .label {
    font-size: 12px !important;
    left: 9px !important;
  }
  .label-float {
    top: 10px !important;
    left: 9px !important;
  }
}

.Revised-Salary-Modal {
  .review-modal {
    height: auto !important;
  }
}

.control-active-float-label {
  .label-float {
    top: -4px !important;
    left: 4px !important;
    font-size: 12px !important;
  }
  .label {
    font-size: 12px !important;
    top: -4px !important;
    left: 4px !important;
  }
}

@media (max-width: 1024px) {
  .review-modal {
    height: 75vh;
  }
}
