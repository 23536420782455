.user {
  &-card {
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000008;
    border-radius: 5px;
    padding: 20px;

    :where(.css-dev-only-do-not-override-1xej1fv).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input {
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      border-top: 0;
    }
    .ant-select-selector {
      border: 0px solid !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #d9d9d9 !important;
    }
  }
}

.not-expand {
  .ant-table-wrapper {
    min-height: 30vh;
    max-height: 30vh;
  }
  .ant-table-wrapper:hover {
    overflow: auto;
  }
  .ant-pagination {
    display: none;
  }
}

.expaned {
  .ant-table-content {
    min-height: 64vh;
    max-height: 64vh;
  }
}
.Leave__policy-table {
  .ant-table-cell {
    font-size: 14px !important;
  }

  .leave-approve {
    color: #d34a7c !important;
  }

  .leave-reject {
    color: red !important;
  }

  .card-title h4 {
    border: none !important;
  }
  .ant-table-wrapper .ant-table-expanded-row-fixed {
    width: auto !important;
  }
}

.user-bank {
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.031372549);
  border-radius: 5px;
}

.earned {
  p {
    letter-spacing: 1.54px;
  }
}

.bg-img {
  position: relative;
  height: 100%;
  background-image: url(https://app.chutti.work/leave_balance_bg.svg);
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: bottom right;
  padding: 20px;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   bottom: -20px;
  //   right: -20px;
  //   width: 168px;
  //   height: 119px;

  //   z-index: 1;
  // }
}

.earn {
  font-weight: 800;
  color: #000;
  letter-spacing: 1px;
}

.Quick__apply_card {
  padding-top: 20px;

  Button {
    width: 160px;
    height: 44px !important;
    font-size: 14px !important;
  }

  .ant-input-outlined:focus-within {
    box-shadow: none !important;
  }
}

.UserAttendance {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000008;
  border-radius: 5px;
  padding: 35px;
}

.user-birth-anniversary-bottom {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.0588235294);
  border-radius: 6px;
  padding: 16px 20px;
  height: 60%;
}

.quick-apply {
  .ant-radio-wrapper span.ant-radio + * {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
}

.no-record.user-announcement {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-content: center;
}
.leaveList-container {
  max-height: 40vh;
  @media screen and (max-width: 1280px) {
    max-height: 50vh;
  }
  @media screen and (max-width: 1024px) {
    max-height: inherit;
  }
}

.applyLeaveModal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    padding-right: 1.6rem;
  }
}
.leavelist-reason {
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .leave-status::before {
//   content: "";
//   position: absolute;
//   top: 23px;
//   left: 0px;
//   width: 8px;
//   height: 8px;
//   border-radius: 50%;
//   background-color: #6747d4;
// }

.lop-count {
  color: red !important;
  font-size: 12px !important;
}

.user--announcement {
  .announcement-notification-container::after {
    height: 73%;
  }
}

.userDashboard-height {
  height: auto;
  padding: 20px 0;
}

.leaveList-container {
  .no-record {
    .leave-list {
      height: calc(35vh - 7px);
      display: flex;
      justify-content: center;
    }
  }
}

.leaveReason-TextArea {
  .ant-input-outlined.ant-input-disabled,
  :where(.css-dev-only-do-not-override-1xej1fv).ant-input-outlined[disabled] {
    background-color: #fff !important;
  }
}

.Quick__apply_card {
  .ant-input-outlined:focus {
    box-shadow: none !important;
    border-color: #d9d9d9 !important;
  }
  .ant-select {
    &-selector {
      box-shadow: none !important;
      &:hover {
        border-color: #eb2f96 !important;
      }
    }
  }
  .ant-select-single {
    height: auto !important;
  }
}
.ant-table-wrapper .ant-table-expanded-row-fixed {
  width: auto !important;
}
// Mobile and Tablets
@media (max-width: 1024px) {
  .quick-apply .ant-radio-wrapper span.ant-radio + * {
    font-size: 14px !important;
  }
  .ant-select-dropdown .ant-select-item {
    font-size: 12px !important;
  }
  .Quick__apply_card Button {
    width: 140px;
    height: 36px !important;
    font-size: 12px !important;
  }
  .quick-apply {
    .ant-input {
      font-size: 12px;
    }
  }
  .ant-select-single .ant-select-selector {
    font-size: 12px;
  }
  .user-calender_icon_single {
    font-size: 14px;
    left: 85% !important;
  }
  .user-calender_icon_double {
    font-size: 14px;
    left: 85% !important;
  }
  .Leave__policy-table .ant-table-cell {
    font-size: 12px !important;
  }
  .employee-table .ant-table-cell p {
    color: #333333;
    font-size: 12px !important;
  }
  .leaveDeleteButton {
    font-size: 20px !important;
  }
  .user-card {
    padding: 15px 15px 30px;
  }
  .user-norecord {
    width: 50px;
  }
  .birth-anniversary .birth-anniversary__emp-hooray span {
    font-size: 13px !important;
  }
  .rdrMonth {
    width: 100% !important;
  }
  .rdrMonths {
    display: flex;
    flex-wrap: wrap;
  }
  .rdrMonthAndYearPickers {
    top: 45px !important;
  }
  .rdrMonthAndYearPickers select {
    font-size: 13px !important;
  }
  .rdrDay .rdrDayNumber span {
    font-size: 13px !important;
  }
  .rdrMonthName {
    font-size: 14px;
  }
  .user-birth-anniversary-bottom {
    height: 57%;
  }
  .ant-tag {
    font-size: 10px !important;
  }
  .Quick__apply_card .ant-select-single {
    height: 28px !important;
  }
  .user-card .ant-input {
    padding-right: 25px !important;
  }
  .quick-apply .ant-select .ant-select-arrow {
    inset-inline-end: 0px !important;
  }
  .Leave__policy-table {
    .ant-avatar-lg {
      width: 35px;
      height: 35px;
      line-height: 34px;
      border-radius: 50%;
    }

    .leave-type-Sick span {
      color: #0e96b0;
      font-size: 13px;
      font-weight: 600;
    }

    .leave-type-Casual span {
      color: #a16f0b;
      font-size: 12px;
      font-weight: 600;
    }
    .employee-table .ant-table-content {
      height: 29vh;
      overflow: auto !important;
      padding-bottom: 2px;
    }
    .ant-table-placeholder .ant-table-cell {
      background-color: transparent;
      position: relative !important;
      left: auto;
      top: auto;
      border: 0px;
    }
    .Leave__policy-table .ant-table-wrapper .ant-table-expanded-row-fixed {
      display: flex;
      width: auto !important;
      justify-content: center;
    }
  }

  .ant-modal-root .ant-modal {
    max-width: calc(100vw - 35px);
  }
  .Quick__apply_card {
    padding-top: 10px;
  }

  .Leave__policy-table {
    .ant-table-wrapper table {
      width: max-content !important;
      min-width: 100%;
      table-layout: auto;
    }
  }
}

@media (max-width: 380px) {
  .quick-apply .ant-radio-wrapper span.ant-radio + * {
    font-size: 11px !important;
  }
}

@media (min-width: 400px) and (max-width: 1024px) {
  .user-card .ant-input {
    padding-right: 32px !important;
  }
}
