.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
}
.rdrCalendarWrapper,
.rdrMonthName {
  font-family: "Rubik", sans-serif;
}
.rdrWeekDay {
  color: #6e6e6e;
}
.rdrDayNumber span {
  color: #000000;
  font-weight: 400;
}
.rdrMonthName {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.rdrNextPrevButton {
  box-shadow: 0px 0px 7px #0000001e;
  background-color: #ffffff;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  &.rdrPprevButton {
    transform: rotate(180deg);
  }
  i {
    background-image: url("./../../../../../assets/images/user/arrow_forward.svg");
    border: none;
    margin: 0;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: inherit;
    transform: none;
  }
}

// .rdrDayNumber
// {
//   width: 32px;
//   height: 32px;
// }
// .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge
// {
//   width: 32px;
//   height: 32px;
//   border-radius: 50%;
// }
.calender {
  &-header {
    border-bottom: 0.5px solid #cfcfcf;
    padding-bottom: 0.5rem;
    margin-left: 1.4rem;
    margin-right: 1.5rem;
  }
}
.rdrMonthAndYearPickers {
  display: none;
  position: absolute;
  top: 10px;
  font-family: "Rubik", sans-serif;
  left: 36px;
  font-weight: 400 !important;
}
.rdrWeekDay {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
}
.rdrDay {
  .rdrDayNumber {
    span {
      font-size: 15px;
    }
  }
}
.rdrDefinedRangesWrapper,
.rdrDateRangePickerWrapper,
.rdrDateRangeWrapper,
.rdrCalendarWrapper,
.rdrMonths.rdrMonthsHorizontal {
  width: 100%;
}
.rdrMonth {
  width: calc(50% - 15px);
}
.rdrMonths {
  justify-content: center;
}
.rdrMonthAndYearPickers select {
  font-family: "Rubik", sans-serif;
  font-size: 1.1rem;
  color: #212529 !important;
}
.rdrMonthAndYearPickers select:hover {
  background-color: transparent !important;
}
.rdrCalendarWrapper {
  min-height: 348px;
}
.rdrNextPrevButton {
  position: absolute;
  top: 48%;
}
.rdrPprevButton {
  left: 0;
}
.rdrNextButton {
  right: 0;
}
.rdrMonthAndYearWrapper {
  height: 20px;
}
.rdrMonthAndYearPickers select {
  padding-left: 0;
  margin-left: -4px;
}
.oneMonth {
  .rdrMonth {
    width: 100%;
  }
  .rdrCalendarWrapper {
    margin-top: 1rem;
  }
  .rdrNextPrevButton {
    display: none;
  }
  .rdrDay {
    height: 60px !important;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 4px;
    right: 1px !important;
    left: 1px !important;
    bottom: 5px;
    pointer-events: none;
    border: 0px solid currentColor;
    z-index: 1;
  }
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 1px;
    right: 1px;
    top: 6px !important;
    bottom: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-picker-dropdown .ant-picker-now {
  display: none !important;
}

.user-calender_icon_double {
  padding-top: 7px;
  position: absolute;
  font-size: 16px;
  z-index: 1;
  left: 21%;
}

.user-calender_icon_single {
  padding-top: 7px;
  position: absolute;
  font-size: 16px;
  z-index: 1;
  left: 39%;
}

.Leave-TimePicker {
  .ant-picker .ant-picker-input {
    height: 33px !important;
  }
}

.half-day-checkbox {
  .ant-checkbox + span {
    padding-top: 4px;
  }
}

.multiple-month {
  .rdrDay {
    height: 55px !important;
  }

  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 1px;
    right: 1px;
    top: 6px !important;
    bottom: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    right: 1px !important;
    left: 1px !important;
    top: 4px !important;
    bottom: 4px !important;
  }
}

.leave_pending::before,
.leave_notApproved::before,
.leave_approved::before {
  content: "";
  position: absolute;
  top: 30px;
  left: 0px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.leave_approved::before {
  background-color: #4ad378;
}
.leave_notApproved::before {
  background-color: #f0391d;
}
.leave_pending::before {
  background-color: gray;
}

.Leave-TimePicker {
  .ant-picker-outlined {
    border: 0px solid !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    box-shadow: none !important;
    &:hover {
      border-color: #eb2f96 !important;
    }
  }
  .ant-picker .ant-picker-input > input {
    font-size: 16px !important;
    font-family: Rubik;
  }
}

@media (max-width: 1024px) {
  .Leave-TimePicker .ant-picker .ant-picker-input > input {
    font-size: 14px !important;
    font-family: Rubik;
  }
  .calender-header {
    margin-left: 0px;
    margin-right: 0px;
  }
  .oneMonth .rdrDay {
    height: 50px !important;
  }
  .applyLeaveModal {
    .addButton._deprovision {
      height: 35px !important;
    }
  }
  .ant-btn.ant-btn-lg {
    font-size: 12px;
    height: 35px;
  }
  .multiple-month .rdrDay {
    height: 50px !important;
  }
  .rdrMonthName {
    padding: 0 0.833em;
  }
  .oneMonth .rdrCalendarWrapper {
    margin-top: 0px;
  }
  .half-day-checkbox .ant-checkbox + span {
    padding-top: 2px;
  }
  .rdrNextPrevButton {
    width: 25px;
    height: 25px;
  }
  .rdrNextPrevButton i {
    width: 15px;
    height: 15px;
  }
  .rdrMonth {
    padding: 0 0.833em 1em 0.833em;
  }
}
