.card-title {
  &::before {
    width: 100%;
    border: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
  }
  h4 {
    margin: 0;
    color: #d34a7c;
    font-size: 1rem;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #d34a7c;
    position: relative;
    z-index: 1;
    font-family: "Rubik", sans-serif;
  }
}
.birth-anniversary {
  font-family: Rubik, sans-serif;
  height: 100%;
  &-container {
    height: 250px;
  }
  &__tab {
    &::before {
      content: "";
      width: 100%;
      border: 1px solid #e2e2e2;
      position: absolute;
      bottom: 0;
    }
  }
  &__timeline {
    padding-left: 4rem;
    max-height: 245px;
    height: 100%;
    @media (max-width: 1366px) {
      padding-left: 3.5rem;
    }
    @media (max-width: 1024px) {
      padding-left: 0;
    }
    .date-header {
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  &__AnnouncementTimeline {
    padding-left: 4rem;
    max-height: 260px;
    height: 100%;
    @media (max-width: 1366px) {
      padding-left: 3.5rem;
    }
  }
  &__list {
    padding-left: 1rem;
    // border-left: 1px solid #cccccc;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    @media (max-width: 1280px) {
      padding-left: 0.5rem;
    }
  }
  &__lists {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    @media (max-width: 1280px) {
      padding-left: 0.5rem;
    }
    @media (max-width: 1024px) {
      padding-left: 0;
    }
  }
  &__list-cont {
    padding: 0.5rem 0.75rem;
    display: flex;
    column-gap: 0.5rem;
    @media (max-width: 1024px) {
      padding-left: 0;
    }
  }
  &__profile-img {
    flex: none;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #f3f3f3;
    margin-right: 10px;
    @media (max-width: 1280px) {
      width: 30px;
      height: 30px;
      margin-right: 2px;
      img {
        max-height: 25px;
      }
    }
    img {
      max-height: 30px;
    }
  }
  .birth-anniversary__emp-hooray {
    flex-direction: column;
    row-gap: 0.25rem;

    & > div {
      column-gap: 0.25rem;

      .birth-anniversary__name {
        line-height: 1;
        font-size: 0.75rem;
        color: #595959;
        font-weight: 300;
      }
    }

    span {
      font-size: 0.875rem;
      color: #333333;
    }
  }
}
.birth-anniversary__date {
  top: 0px;
  left: -12px;
  width: 24px;
  color: #ffffff;
  background-color: #d34a7c;
  border-radius: 2px;
  height: 24px;
  font-size: 0.75rem;
  font-weight: 500;
  overflow-y: hidden;
  z-index: 5;
}

.birth-anniversary__month {
  top: 1px;
  left: -55px;
  color: #595959;
  font-size: 0.875rem;
}

.birth-anniversary-top,
.birth-anniversary-bottom {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 3px 8px #0000000f;
  border-radius: 6px;
  padding: 16px 20px;
  height: 32.1%;
  // height: calc(50% - 18px);
  // min-height: 300px;
  // height: calc(100vh - 570px);
  @media (max-width: 1366px) {
    padding: 18px;
  }
}
.dashboard-container__birth-anniversary {
  height: 100%;
}
.container {
  @media (max-width: 1340px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

.dashboard-announce-body {
  max-width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.announcement-notification-content-list {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.announcement-notification-container {
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  transition: scroll-behavior 0.3s ease;
}
// .announcement-notification-container:hover {
//   overflow-y: auto;
// }
.announcement-notification-container::after {
  height: 70%;
  padding-left: 1rem;
  border-left: 1px solid #cccccc;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 63px;
  margin-top: 90px;
  @media (max-width: 1366px) {
    left: 55px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}

.birthday-anniversary-container::after {
  height: 70%;
  padding-left: 1rem;
  border-left: 1px solid #cccccc;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 63px;
  margin-top: 90px;
}
.announceHtml {
  @media (max-width: 1024px) {
    max-width: 290px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
