::-webkit-scrollbar {
  width: 3px;
 transform: scaleY(0);
  transition: transform 0.3s ease-in-out;}

::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  transition: background 0.3s ease-in-out;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
}

 .ant-table-wrapper {
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background: transparent;
      transition: background 0.3s ease-in-out;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
         background-color: #a6a6a6;
      }
    }
  }