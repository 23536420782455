@import "/src/components/Header/Header.scss";
@import "/src/pages/Dashboard/dashboard.scss";
@import "/src/components/Sidebar/Sidebar.scss";
@import "/src/components/AddButton/AddButton.scss";
@import "/src/components/EmployeeTable/EmployeeTable.scss";
@import "/src/pages/Organization/Organization.scss";
@import "/src/pages/Organization/Department/Department.scss";
@import "/src/components/AssetMaster/AssetMaster.scss";
@import "/src/assets/styles/variable";
@import "/src/pages/Organization/EditOrganisation/EditOrganisation";
@import "/src/pages//Organization/Designation/DesignationEditor/designation.scss";
@import "./component/index.scss";
@import "./pages/index.scss";
@import "./pages/index";
@import "./pages/assessment/add-assessment";
@import "./mixins";
@import "./scrollBar";

body {
  font-family: "Rubik", sans-serif;
}

.backgroundImage {
  background-image: url("../images/background.jpg");
  position: absolute;
  height: 100%;
  overflow: hidden;
  background-size: 100% 100%;
  background-position: top right;
}
.course_backroundimg {
  background-image: url("../images/nopreview.jpeg");
  height: 100%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #e9eaec;
}
.noPreviewImg {
  object-fit: contain !important;
}
.custom-cell-class {
  width: auto;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loader-container {
  background: rgba(0, 0, 0, 0.7);
  /* Semi-transparent overlay */
  z-index: 9999;
  /* Ensure it's above other page content */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  background-color: none;
}

.ant-spin-spinning {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
  background-color: rgba(255, 255, 255, 0.5); /* Use an RGBA color for transparency */
  backdrop-filter: blur(10px);
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 1;
}

.ant-table-cell {
  text-align: left;
}

.error-message {
  color: red;
  font-weight: 300;
  font-size: 13px;
}
thead {
  position: sticky;
  top: 0;
  height: 60px;
}
.ant-select-suffix {
  color: rgba(0, 0, 0, 0.52) !important;
}



.green-button-to-verify {
  border-radius: 10px;
  width: 50%;
  background-color: green;
  padding: 10px;
  color: white;
}

.modal-loader {
  position: absolute !important;
  opacity: 0.5 !important;
  height: calc(100% - 60px);
  top: 55px;
  &.event {
    opacity: 0.99;
    background-color: #ffffff80 !important;
  }
}

.upload-error-tooltip {
  .ant-tooltip {
    display: none;
  }
}

.arrange-ino {
  .ant-upload-wrapper {
    overflow: hidden !important;
    height: 250px !important;
    width: 100% !important;
  }
}

.multi-ques {
  .ant-upload-wrapper {
    overflow: hidden !important;
    height: 155px !important;
    width: 355px !important;
  }
}

.course-upload {
  .ant-upload-wrapper {
    overflow: hidden !important;
    height: 255px !important;
    width: 355px !important;
  }
}
.chapter-zip-upload,
.certificate_upload {
  .ant-upload-wrapper {
    overflow: hidden !important;
    height: 155px !important;
    width: 355px !important;
  }
}
.edit-personal-details {
  .ant-upload-wrapper {
    overflow: hidden !important;
    height: 120px !important;
    width: 130px !important;
  }
}

#imageError {
  top: 25px !important;
}

.imageupload-div {
  margin-bottom: 15px !important;
}
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}
.sign-input {
  label {
    position: absolute;
    top: 0px;
  }
}
input[type="newpassword"] {
  -webkit-text-security: disc;
}
.right-arrow {
  filter: brightness(0) invert(1);

  margin-left: 5px;
}
.sign_in {
  .input-custom {
    --tw-translate-y: 0 !important;
    top: -15px !important;
  }
}
.ant-badge-count {
  width: -webkit-fill-available;
}
.birth-anniversary {
  .ant-spin-spinning {
    height: calc(100% - -40px) !important;
    top: -10px !important    ;
  }
}
.Error-popup-modal {
  .ant-modal-content {
    border-radius: 20px !important;
  }
  Button {
    height: 40px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}
.asset-header {
  .header-status {
    border-right: 2px solid #878787;
    padding-right: 10px;
    &:last-child {
      border-right: none;
    }
  }
}
.isModalVisible
{
  .ant-modal-close
  {
    display: none;
  }
}

