.draft-js-editor {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.toolbar {
  display: flex;
  background-color: #f1f1f1;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.toolbar button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
}

.toolbar button:hover {
  background-color: #e0e0e0;
}

.toolbar button.active {
  font-weight: bold;
}
/* 
.left-aligned {
  text-align: left;
}
  
.center-aligned {
  text-align: center;
}
.right-aligned {
  text-align: right;
} */

/* .align-left {
  text-align: left;
}
.align-center {
  text-align: center !important;
}
.align-right {
  text-align: right;
} */

.cms-admin-modal {
  .cke_notifications_area:has(.cke_notification_warning) {
    display: none;
  }
}
.cke_notifications_area {
  display: none;
}
.announcement-file {
  padding: 5px;
}
.editor-alignment-center div {
  text-align: center;
}
.editor-alignment-right div {
  text-align: right;
}
.editor-alignment-left div {
  text-align: left;
}
.t16lpgj {
  border: none;
  background: #fff;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
}

.public-DraftEditorPlaceholder-inner {
  font-size: 14px !important;
}
