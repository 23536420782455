@import "./../../../../assets/styles/_variable";

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.break-all {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.review-form {
  // margin-top: 4rem;
  display: block;
}

.img-data {
  .ant-image {
    width: 64px;
    height: 64px;
    @media (max-width: 1280px) {
      width: 40px;
      height: 40px;
    }
  }
  img:hover {
    cursor: pointer;
  }
}
.personal-card-button {
  background: $primary-color 0% 0% no-repeat padding-box;
  border-radius: 0px 6px 0 6px;

  width: 26px;
  height: 26px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  .material-symbols-outlined {
    color: white !important;
    font-size: 18px;
  }
}
.tab-container {
  .personal-card-button {
    left: 0 !important;
    border-radius: 6px 0px 0px 0px;
  }
}
.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.chip {
  color: #595959;
  font-size: 12px;
}
.employee {
  &-name {
    color: $secondary-color;
    font-size: 14px;
    font-family: $rubik-font;
    margin-bottom: 0px;
  }
}
.employee-details__personal-card {
  width: 30%;
  height: calc(100vh - 70px);
  padding-bottom: 20px;
  padding-top: 20px;
}

.employee-details__card {
  transition: transform 0.3s ease-in-out;
  .img-data {
    position: relative;
    display: flex;
    padding: 15px 15px 5px 15px;
    .edit-profile:hover {
      background-color: #c9c9c9;
      transform: scale(1.2);
      color: #d34a7c;
    }
  }
  .ant-spin-spinning {
    position: absolute;
  }
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .ant-collapse {
    &-content-box {
      padding: 0 !important;
    }
    &-item {
      background-color: #ffffff;
    }
    &-header {
      padding-left: 0 !important;
      padding-right: 0 !important;
      &-text {
        color: $primary-color;
        font-weight: 400;
        font-family: $rubik-font;
      }
    }
    &-expand {
      &-icon {
        position: absolute;
        right: 0;
      }
    }
  }
}

.employee-details__card .img-data img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -o-object-fit: fill;
  object-fit: fill;
  background-color: #f9f8fd;
  padding: 4px;
  @media (max-width: 1280px) {
    width: 42px;
    height: 42px;
  }
  @media (max-width: 1440px) {
    width: 52px;
    height: 52px;
  }
  @media (max-width: 1860px) {
    width: 52px;
    height: 52px;
  }
}
.employee-details__card .img-data .edit-profile {
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  margin-left: 10px;
  width: 28px;
  height: 28px;
  right: 50px;
  top: 22px;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.4s ease;
}

.employee-details__card .img-data .employee-job {
  padding-left: 20px;
  word-break: break-word;
  @media (max-width: 1280px) {
    padding-left: 5px;
  }
}
.employee-details__card .img-data .employee-job .employee-name {
  color: #333333;
  font-size: 16px;
  margin-top: 10px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.employee-details__personal {
  // width: 48vh;
  padding: 20px;
  border-top: 1px solid #e5e5e5;
}
.employee-details__personal-main {
  overflow-y: hidden;
  height: calc(80vh - 40px);
}
.employee-details__personal-main:hover {
  overflow-y: auto;
}

@media screen and (max-width: 1366px) {
  .employee-details__personal-main {
    height: calc(77vh - 110px);
  }
}
.employee-details__personal-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.employee-details__personal-head p {
  color: #d34a7c;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.employee-details__personal-head button {
  font-size: 12px;
  line-height: 28px;
  color: #333333;
}
.employee-details__personal-head button .material-symbols-outlined {
  font-size: 16px;
  position: relative;
  top: -1px;
}
.employee-details__personal ul {
  list-style-type: none;
  overflow-x: hidden;
}
.employee-details__personal ul li {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.employee-details__personal ul li div {
  text-align: left;
}
.employee-details__personal ul li div:nth-child(1) {
  max-width: 170px;
  min-width: 50%;
  color: #333333;
  font-weight: 500;
  font-family: $rubik-font;
}
.employee-details__personal ul li div:nth-child(2) {
  font-family: $rubik-font;
  color: #595959;
}
@media (max-width: 991px) {
  .employee-details__container {
    flex-direction: column;
  }
  .employee-details__personal-card,
  .employee-details__tab-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .employee-details__tab-card .mat-tab-labels {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .employee-details__tab-card .salary-btn-group {
    margin-top: 20px;
    position: relative !important;
  }
}
@media (max-width: 576px) {
  .employee-details__personal-card {
    padding: 0px 5px;
  }
  .employee-details__tab-card {
    padding: 0px;
  }
  .employee-details__tab-card .mat-tab-body-wrapper {
    padding: 10px 10px;
  }
}
.no-record.salary {
  height: calc(35vh - 12px);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100px;
  }
}
.no-record.assets {
  height: calc(35vh - 5px);
  display: flex;
  justify-content: center;
  align-items: end;
  img {
    max-width: 100px;
  }
}
.no-record.appraisal {
  height: calc(35vh - 7px);
  display: flex;
  justify-content: center;
  align-items: end;
  img {
    max-width: 100px;
  }
}
.draft-message {
  top: 10%;
  color: #595959;
  font-size: 14px;
}
.documents-container {
  max-height: 60vh;
  height: 100%;
  overflow-y: hidden;
}
.documents-container:hover {
  overflow-y: auto;
}
.image_view_profile {
  .imageSizeModal {
    height: 100%;
    width: 100%;
  }
}
.addressSmall {
  font-size: 1rem;
}
.salary-history {
  .ant {
    &-table {
      &-content {
        min-height: 50vh;
        max-height: 60vh;
      }
      &-placeholder {
        .ant-table-cell {
          background-color: #fff;
          position: absolute !important;
          left: 45% !important;
          top: 40% !important;
          border: 0;
        }
      }
    }
  }
}
.deporvision {
  .ant-modal-close-x {
    display: none;
  }
}
.mail_section {
  box-shadow: 0px 4px 6px #00000014;
}
.appraisalBg {
  background-color: #ffe2ec;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  margin-top: 0.5rem;
  span {
    font-size: 0.8rem;
  }
}
.appraisalImg {
  max-width: 20px;
  max-height: 20px;
  margin-right: 0.1rem;
  background-color: transparent !important;
}

.hover-text {
  &:hover {
    .emp-name,
    .employee-name {
      color: #d34a7c;
    }
  }
}

.employee__details {
  .modal-loader {
    position: absolute !important;
    opacity: 1 !important;
    height: 100%;
    top: 0px;
  }
}

.employee_info {
  .ant-tabs .ant-tabs-content {
    // min-height: 70vh;
    min-height: 55vh;
  }
}
@media screen and (max-width: 1300px) {
  .employee-details__personal {
    width: 65vh;
  }
}
@media (max-width: 1024px) {
  .documents-container {
    overflow: auto;
    padding-right: 6px;
  }
  .employee .ant-tabs-content-holder {
    padding-left: 5px;
    padding-right: 5px;
  }
  .employee-details__personal-main {
    overflow: hidden;
    height: auto !important;
  }
  .employee-details__personal {
    width: 43vh;
  }
}

@media (max-width: 720px) {
  .employee-details__personal {
    width: 95vw;
  }
}
