.approved-screen .doc {
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 90%;
  width: 700px;
  margin: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  max-height: 80vh;
  overflow-y: auto;
}

.documentUpload {
  &.upload {
    .ant-upload-list-item {
      border-color: red !important;
    }
  }
  .ant-tooltip {
    display: none;
  }
}

.missing-doc-screen .header__logo {
  padding: 1rem 3rem !important;
  height: auto;
}

// .missing-doc-screen {
//   .document-view:hover {
//     background-color: inherit;
//   }
// }

@media (max-width: 1024px) {
  .missing-doc-screen .header__logo {
    padding: 1rem 23px !important;
    height: auto;
  }
}
