@import "./../../assets/styles/variable";
.employee {
  &-table {
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.0588235294);
    border-radius: 6px;
    // padding: 1rem;
    padding: 15px;
    height: 72%;
    // height: calc(100vh - 260px);
    // height: 106%;
    // position: absolute;
    transition: 0.5s;
    bottom: 20px;
    left: 0;
    width: 100%;
    // .ant-table-content {
    //   max-height: calc(100vh - 250px);
    //   height: calc(100vh - 260px);
    // }
    .ant-table-wrapper {
      margin-top: 10px;
    }
    @media (max-width: 767px) {
      position: unset;
      height: 360px;
    }
    th {
      background-color: transparent !important;
    }
    .ant-table-column-title {
      font-size: 14px;
      color: #333333;
    }

    &__card {
      padding: 16px 0px 0px 16px;

      &::before {
        width: 100%;
        border: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
      }
    }
    .ant-table-cell {
      padding: 12px 13px;
      font-size: 12px;
      color: #595959;
      border-top: none;

      p {
        color: #333333;
        font-size: 14px;
      }
    }
  }
  &-image_container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f3f3f3;
    padding: 0;
    margin-right: 10px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: fill;
      text-indent: -999em;
    }
  }
}

.ant-upload,
.ant-upload-list-item-container {
  width: 120px !important;
  height: 120px !important;
}

.image-upload {
  p {
    color: #595959;
    font-family: Rubik, sans-serif;
    line-height: 1.1;
    font-size: 10px;
    margin-top: 12px;
  }
}

.add-employee_form {
  .ant-upload-wrapper {
    height: 120px;
    overflow: hidden;
  }
}

.search-box {
  background: #f5f5f5;
  height: 35px;
  border-radius: 40px;
  padding: 5px;
  transition: 0.4s;
}

.search-box:hover {
  box-shadow: 0 0 0 2px rgba(255, 5, 105, 0.06);
  border-color: #f759ab;
  background-color: #ffffff;
}

.search-btn {
  color: $primary-color;
  text-decoration: none;
  &:hover {
    background-color: transparent;
  }
}

.search-txt {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0;
  flex: 1;
}

.search-box > .search-box_hover {
  width: 240px;
  padding: 0 6px;
}
.expand {
  .ant {
    &-table-wrapper {
      height: 90%;
      overflow-y: hidden;
      padding-right: 7px;
      transition: overflow 0.3s ease-in-out;
      &:hover {
        overflow: auto;
      }
    }
    &-pagination {
      display: none;
    }
    &-table-selection-column {
      display: none;
    }
  }
}
.deleteIcon,
.employee-deprovision {
  color: $primary-color;
  background: #f5f5f5;
  &:hover {
    color: #fff;
    background: $primary-color;
  }
}
th {
  &::before {
    display: none;
  }
}
.filterStatus {
  margin-right: 4px;
  background-color: #d23b7d;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 10px !important;
  left: -4px;
}

.highlighted-row {
  background-color: #fff0f3;
}
.expand-icon {
  transition: transform 0.3s ease-in-out;
  margin-top: 7px;
  &:hover {
    transform: scale(1.2);
  }
}

.yourClassName {
  &:hover {
    .tool--tip {
      display: none !important;
    }
  }
}
.expanded {
  .employee-table {
    &.responsive {
      .ant-table {
        &-content {
          height: 63vh;
          overflow-y: hidden;
          &:hover {
            overflow-y: auto !important;
          }
        }
        &-thead {
          z-index: 7;
          background-color: #fff;
        }
      }
      .ant-table-wrapper table {
        width: 91vw;
      }
    }
  }
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  margin-right: 7px;
}
.employee-table {
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    background-color: inherit !important;
  }
  .ant-table-content {
    overflow: hidden;
    padding-bottom: 2px;
    &:hover {
      overflow: auto;
    }
  }
}
