.profilePicture {
  p {
    background: $border-gradient;
    width: 50px;
    height: 50px;
    margin-bottom: 6px;
    margin-left: 10px;
    font-size: 1.35rem;
  }
  .ant-dropdown-trigger {
    display: block;
  }
}

.logout-dropdown {
  padding: 4px 14px !important;
  transition: opacity 0.3s ease-in;
  top: 7px;
  right: -2px;
  width: 175px;
  box-shadow: 0px 3px 8px #00000029;
  border-radius: 0px !important;
  border: 1px solid #e3e3e3;
  &.visible {
    display: block;
    opacity: 1;
  }
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 4px;
    left: 0;
    top: 0;
    border-radius: 3px 0 0 3px;
    background: $primary-color;
    background: $border-gradient;
  }
  li {
    padding: 4px 8px !important;
    border-radius: 0px !important;
    &:hover {
      background-color: #fff !important;
    }
  }
}

.logout-item {
  padding: 5px;
  border-bottom: 1px solid $gray;
  p {
    color: $secondary-color;
  }

  &:last-child {
    border-bottom: none;
  }
}
.logout-icon {
  .material-symbols-outlined {
    color: $primary-color;
    font-size: 22px;
  }
}
