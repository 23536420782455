// salary details
.ant-dropdown-menu-item-only-child {
  &:hover {
    background-color: #fff !important;
    color: #d23b7d;
    transition: all 0.3s ease-in;
  }
}
.ant-dropdown-menu-vertical {
  background-color: #fff !important;
}

.modal {
  &-documentPreview {
    .ant-modal-close-x svg {
      fill: #fff !important;
    }
  }
}
.salary_details {
  .ant {
    &-radio {
      background-color: #ffffff !important;
      &::after {
        background-color: #d23b7d !important;
      }
    }
    &-tabs {
      &-tab {
        padding-top: 0 !important;
        &-active {
          .ant-tabs-tab-btn {
            background-color: #ffedf4 !important;
            border-radius: 4px;
            height: 38px;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 24px;
          }
        }
        .ant-tabs-tab-btn {
          background-color: #e3e3e3;
          border-radius: 4px;
          height: 38px;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 24px;
        }
      }
      &-ink-bar {
        display: none;
      }
      &-nav {
        &::before {
          display: none;
        }
      }
    }
  }
  .employee-assets {
    max-height: 60vh;
    height: 100%;
    .ant-collapse-header {
      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.88);
      }
    }
    .asset-description {
      color: #878787 !important;
    }
  }
}

// projects
.project-page {
  .ant {
    &-collapse {
      margin-bottom: 30px;
      &-expand-icon {
        position: absolute;
        right: 0;
      }
      &-arrow {
        background: #d23b7d;
        width: 22px;
        height: 22px;
        border-radius: 3px;
        color: #ffffff !important;
        display: flex;
        justify-content: center;
      }
      &-content-box {
        padding: 0 !important;
      }
      &-header {
        height: 50px;
        background-color: #fdf2f7;
        position: relative;
      }
    }
  }
}
// edit-organization

.edit-organization {
  .float-label input {
    padding-left: 0;
  }
}

// reviewform modal
.review-modal {
  .ant {
    &-switch {
      background: #e3e3e3 !important;
      &-checked {
        background: #d34a7c !important;
      }
    }
    &-select {
      &-selection-placeholder {
        color: #333333;
      }
      &-selector {
        background-color: rgba(0, 0, 0, 0.02);
        border: 0 !important;
      }
    }
  }
}
// add course
.addcourse {
  .ant {
    &-modal {
      &-body {
        justify-content: center;
        display: flex;
        height: 100%;
      }
    }
    &-steps {
      &-item {
        &-title {
          font-size: 20px;
          &::after {
            background-color: unset !important;
            border: 1px dashed !important;
          }
        }
        &-finish {
          .ant-steps-item-title {
            &::after {
              background-color: none;
              border: 1px dashed !important;
            }
            color: #d34a7c !important;
          }
          .ant-steps-item-icon {
            background-color: #d34a7c;
            .anticon svg {
              fill: #fff;
              width: 20px;
              height: 15px;
            }
          }
        }
        &-process {
          .ant-steps-item-title {
            color: #d34a7c !important;
          }
          .ant-steps-item-icon {
            background-color: #fff;
            .ant-steps-icon {
              color: #d34a7c;
            }
            .anticon svg {
              fill: #fff;
              width: 20px;
              height: 15px;
            }
          }
        }
        &-wait {
          &-title {
            color: #d34a7c;
          }
          .ant-steps-item-icon {
            background-color: #fff;
            border-color: #acacac;
            .ant-steps-icon {
              color: #acacac;
            }
            .anticon svg {
              fill: #fff;
              width: 20px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}
.courseDetails-cc {
  .ant-switch-inner {
    background-color: #7d7d7d1a;
  }
}

.ant {
  &-notification-notice-close {
    // top: 40% !important;
    top: 12% !important;
    width: 0px !important;
  }
  &-table-placeholder {
    .ant-table-cell {
      background-color: transparent;
      position: absolute !important;
      left: 50%;
      top: 50%;
    }
  }
  &-picker {
    &-separator {
      position: relative;
      display: inline-block;
      width: 1em;
      height: 26px !important;
      color: #8c8c8c !important;
      font-size: 17px;
      vertical-align: top;
      cursor: default;
      transform: rotateX(100deg);
    }
  }
  &-modal-content {
    .ant-modal-close-x {
      svg {
        fill: #d23b7d;
        width: 22px;
        height: 22px;
      }
    }
  }
  &-badge-count {
    padding: 0 !important;
  }
  &-switch {
    background-color: #7d7d7d1a !important;
    &-checked {
      background-color: #d23b7d !important;
    }
  }
  &-select {
    &-dropdown {
      min-width: 125px !important;
    }
    &-suffix {
      color: #000 !important;
    }
    &-selection-search {
      left: -1px !important;
    }
  }
  &-picker {
    &-suffix {
      color: #d34a7c !important;
    }
  }
  &-radio {
    background-color: #ffffff !important;
    &-inner {
      background-color: #ffffff !important;
      &::after {
        background-color: #d23b7d !important;
        top: 50%;
        left: 50%;
        transform: translate(-49%, -52%) !important;
        margin-block-start: 0 !important;
        margin-inline-start: 0 !important;
        width: 11px !important;
        height: 11px !important;
      }
    }
  }
  &-checkbox {
    &-inner {
      border: 2px solid #878787 !important;
    }
    &-checked {
      .ant-checkbox-inner {
        border: 1px solid #d23b7d !important;
      }
    }
  }
}
.relative {
  label {
    color: #878787 !important;
  }
}
.ant-select-selector {
  cursor: pointer !important;
}
.ant-table {
  // &-content {
  //     max-height: 63vh;
  //     height: 100%;
  //     overflow-y: auto;
  // }
  &-thead {
    z-index: 5;
    background-color: #fff;
  }
}
.edit-user {
  .ant-select-selection-item {
    padding-left: 0 !important;
  }
}
.basicForm-page {
  .ant-select-selection-item {
    padding-left: 10px !important;
  }
}
.ant-tooltip-inner:has(> .leave-tooltip) {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.ant-tooltip-inner {
  background-color: #f5f5f5 !important;
  color: #333333 !important;
  border: 1px solid #e0e0e0 !important;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
}
.ant-tooltip-arrow {
  &::before {
    background-color: #f5f5f5 !important;
  }
  &::after {
    border: 1px solid #e0e0e0 !important;
  }
}

.__date {
  .ant-input:focus,
  .ant-picker:focus {
    background-color: #f5f5f5;
  }
}
.__radio {
  .ant-radio-wrapper {
    span {
      font-size: 18px;
      color: #000;
    }
  }
}
.ant-notification-notice {
  z-index: 99 !important;
  position: relative !important;
}

.apexcharts-slices {
  cursor: pointer;
}

.leaveDeleteButton {
  color: #333333;
  opacity: 0.9;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      color: #333333;
    }
  }
  &:hover {
    color: #d34a7c;
  }
}

@media (max-width: 1024px) {
  .ant-notification-notice {
    width: 230px !important;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 11px !important;
  }
}
