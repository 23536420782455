@import "~@flaticon/flaticon-uicons/css/all/all";

.__announcement {
  .__date {
    border-right: 1px solid #949494;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
}

.add-announcement {
  .ant-modal-title {
    margin-bottom: 10px;
  }

  width: 650px !important;

  .ant-picker-calendar-header {
    justify-content: center !important;
  }

  .material-symbols-outlined {
    font-weight: 300;
    color: #cacaca;
  }

  .ant-picker-panel {
    border-top: 0 !important;
  }

  .ant-picker-body {
    padding-top: 0 !important;

    thead {
      height: 35px !important;
    }

    th {
      color: #d23b7d;
      font-weight: bold;
    }
  }
}

.announcement {
  &-title {
    color: #d34a7c;
    font-weight: 500;
    padding-top: 30px;

    span {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #d34a7c;
        left: 0;
        bottom: -25px;
      }
    }
  }

  &-mail {
    border-top: #e3e3e3 1px solid;
    margin-top: 20px;
  }

  &-menu {
    min-width: 240px;
    padding-top: 30px;
    border-right: #e3e3e3 1px solid;
    min-height: calc(100vh - 268px);
    padding-right: 10px;
    border-radius: 5px;

    &--items {
      li {
        height: 52px;
        display: flex;
        align-items: center;
        color: #212121;
        cursor: pointer;

        i {
          padding-left: 16px;
          padding-right: 12px;
        }

        &.active,
        &:hover {
          background-color: #fff0f5;
          color: #d34a7c;
        }
      }
    }
  }

  &-mailBody {
    padding: 30px;
  }

  &-search {
    width: 568px;
    height: 48px;

    .ant-select {
      width: 100%;
      height: 48px;
      border-radius: 24px;
      background-color: #efefef;
      font-family: "Rubik", sans-serif;
      font-size: 17px;
    }

    .fi-rr-search {
      position: absolute;
      left: 15px;
      top: 16px;
      color: #6f6f6f;
      font-size: 14px;
    }

    .fi-rr-settings-sliders {
      position: absolute;
      right: 11px;
      top: -32px;
      background-color: #efefef;
      padding-right: 5px;
    }

    .ant-select-selection-search-input,
    .ant-select-selection-placeholder {
      padding-left: 28px !important;
    }

    .ant-select-show-search:where(.css-dev-only-do-not-override-1xej1fv).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: transparent;
      background-color: transparent;
    }
  }
}

.announcement-search--filter {
  position: relative;
}

.announcement-search {
  ::-webkit-scrollbar {
    width: 0px !important;
    display: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    overflow: auto !important;
  }
}

.filler-form {
  font-family: "Rubik", sans-serif;
  padding: 10px 20px;
  max-width: 532px;
  box-shadow: 0px 0px 10px #00000029;
  border: 0.5px solid rgba($color: #707070, $alpha: 0.5);

  position: absolute;
  top: -5px;
  left: 37px;
  width: 532px;
  height: 207px;
  right: -34px;
  z-index: 1;
  background-color: #fff;

  .filter-close:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .form-group {
    border-bottom: #e3e3e3 1px solid;
    display: flex;
    align-items: center;
    height: 33px;
    font-weight: 500;

    .ant-picker-suffix {
      cursor: pointer !important;

      .anticon .anticon-calendar {
        cursor: pointer !important;
      }

      .anticon .anticon-calendar:hover {
        background-color: rgba(0, 0, 0, 0.06) !important;
      }
    }

    .ant-picker-suffix:hover {
      background-color: rgba(0, 0, 0, 0.06) !important;
    }

    label {
      font-size: 14px;
      color: #d34a7c !important;
      font-weight: 400;

      &.date {
        width: 54px;
      }
    }

    .form-control {
      color: #2f2f2f;
      font-size: 14px;
      width: 100%;
      font-family: "Rubik", sans-serif;
      padding-left: 5px;

      &:focus-visible {
        outline: none;
      }
    }

    .ant-picker {
      width: 100%;
      padding-left: 0px !important;
      padding-right: 0 !important;
      border: none !important;
      box-shadow: none !important;
    }

    :where(.css-dev-only-do-not-override-1xej1fv).ant-picker-outlined:focus,
    :where(.css-dev-only-do-not-override-1xej1fv).ant-picker-outlined:focus-within {
      box-shadow: none;
    }

    :where(.css-dev-only-do-not-override-1xej1fv).ant-picker-outlined {
      border-color: transparent !important;
    }

    :where(.css-dev-only-do-not-override-1xej1fv).ant-picker .ant-picker-input > input {
      text-align: left;
      color: #2f2f2f;
      font-size: 14px;
      font-family: "Rubik", sans-serif;
    }
  }
}

.bnt-secondary {
  border-color: #d34a7c;
  color: #d34a7c;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
}

.bnt-filter-search {
  font-size: 14px;
}

.ant-btn {
  font-family: "Rubik", sans-serif;
}

.form-group-multiple {
  .react-mail-chips {
    overflow: auto;
    height: 100%;
    margin-top: 0 !important;
  }

  .react-mail-chips > .chip {
    background-color: white !important;
    border: 1px solid #b2b2b2;
    border-radius: 25px !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding: 3px 4px 4px 10px !important;

    .remove-chip-btn {
      color: #d23b7d !important;
    }
  }

  .react-mail-chips .chip-input {
    font-size: 14px !important;
    font-family: "Rubik", sans-serif !important;
    font-weight: 500 !important;
  }
}

.singleAnnouncement {
  &--header {
    margin-bottom: 28px;
  }

  &--header--title {
    color: #2f2f2f;
    font-size: 28px;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
  }

  &--body {
    background-color: #f5f5f5;
    padding: 30px;
    min-height: calc(100vh - 450px);
    height: auto;

    .receive-mail {
      .profile {
        width: 66px;
        height: 66px;
        background-color: #d34a7c;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #fff;
      }

      .email-lists {
        h6 {
          color: #2f2f2f;
          font-size: 17px;
        }

        p {
          color: #949494;
          font-size: 14px;
        }
      }
    }

    .mail-body {
      margin-top: 32px;

      p {
        color: #707070;
        font-size: 15px;
      }
    }
  }
}

.dateStyle {
  color: #d34a7c;
  font-size: 15px;
}

.addAnnouncements {
  &--textEditor {
    display: flex;
    flex-wrap: wrap;

    .rdw-editor-toolbar {
      order: 2;
      width: 100%;
    }

    .rdw-editor-main {
      order: 1;

      height: 500px;
      width: 100%;
    }
  }
}

.quill {
  display: flex;

  .ql-toolbar {
    order: 2;
  }

  .ql-container {
    order: 1;
  }
}

.new-message {
  &_requestion {
    min-width: 1050px !important;
    width: 100%;

    .ant-modal-header {
      // padding-left: 8px;
      border-bottom: 1px solid #00000029 !important;
      padding-bottom: 13px;
    }

    .ant-modal-title {
      border-bottom: 3px solid #d34a7c !important;
      padding-bottom: 15px !important;
    }
  }
}

.mailList {
  height: 480px;
  overflow: auto;
}

@media (max-width: 1399px) {
  .mailList {
    height: 400px;
    overflow: auto;
  }
}

.react-mail-chips {
  border: none !important;
  padding-top: 0 !important;
  margin-top: 9px;
  margin-bottom: 9px;
  // .chip-input {
  //     padding-right: 41rem !important;
  // }
}

announcement-mailBody {
  .ant-select-multiple .ant-select-selection-overflow {
    padding-left: 35px !important;
  }
}

.react-mail-chips > .chip {
  background-color: white !important;
  border: 1px solid #b2b2b2;
  border-radius: 25px !important;
  // font-family: 'Rubik', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #3a3a3a;

  .remove-chip-btn {
    color: #d23b7d !important;
    padding-right: 5px;

    svg {
      width: 17px !important;
      height: 17px !important;
    }
  }
}

.react-mail-chips .chip-input-content {
  // font-family: 'Rubik', sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.react-mail-chips .chip-input {
  font-family: "Rubik", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.public-DraftStyleDefault-ltr {
  color: #222 !important;
  font-size: auto;
  font-family: Arial, Helvetica, sans-serif;
  // font-family: "Rubik", sans-serif !important;
  font-weight: auto;

  ::placeholder {
    color: #707070;
    font-size: 18px;
    // font-family: "Rubik", sans-serif;
    font-weight: 400;
  }
}

// .DraftEditor-content {
//   ::placeholder {
//     color: #707070;
//     font-size: 18px;
//     font-family: "Rubik", sans-serif;
//     font-weight: 400;
//   }
// }
.public-DraftEditorPlaceholder-inner {
  color: #707070;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.mail-dropdown {
  .ant-space-item {
    color: #707070 !important;
    font-size: 17px !important;
    font-weight: 400 !important;

    // font-family: 'Rubik', sans-serif !important;
    span {
      font-size: 14px !important;
    }
  }

  .ant-dropdown-menu-item .ant-dropdown-menu-item-only-child {
    color: #707070 !important;
    font-size: 16px !important;
    font-weight: 400 !important;

    // font-family: 'Rubik', sans-serif !important;
    :hover {
      background-color: #6f6f6f;
    }
  }
}

.mail-dropdown-icon {
  .ant-space-item {
    color: #707070 !important;

    // font-family: 'Rubik', sans-serif !important;
  }

  .ant-dropdown-menu-item .ant-dropdown-menu-item-only-child {
    color: #707070 !important;

    // font-family: 'Rubik', sans-serif !important;
    :hover {
      background-color: #6f6f6f;
    }
  }
}

.ant-picker-today-btn {
  color: black !important;
}

.compose-mail-icon {
  .ant-btn.ant-btn-circle.ant-btn-lg {
    min-width: 43px !important;
  }
}

.body-content {
  overflow-y: auto;
  height: 264px;
  display: flex;
  flex-direction: column;
}

.body-content-edit {
  height: 216px;
  margin-bottom: 3rem;
}

.DraftEditor-root {
  // border: 1px solid;
  // min-height: 100%;
  flex: 0 0 auto;
  height: auto !important;

  .DraftEditor-editorContainer,
  .DraftEditor-root,
  .public-DraftEditor-content {
    min-height: 200px !important;
  }
}

.files-uploaded-list {
  max-width: 330px;
  margin-top: 7px;
}

.file-name {
  max-width: 290px;
  min-width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.announcement-search {
  .search-announcement {
    width: 100%;
    height: 48px;
    border-radius: 24px;
    background-color: #efefef !important;
    font-family: "Rubik", sans-serif;
    font-size: 17px;
    padding-left: 34px;
  }
}

.announce-delete-btn {
  height: 45px !important;
  padding: 0 50px !important;
  font-size: 19px !important;
}

.show-modal-content {
  p {
    color: #2f2f2f !important;
  }
}

.custom-calendar {
  position: static !important;
  transform: none !important;
  display: block !important;
  margin: 0 auto;
}

.ant-radio-wrapper span.ant-radio + * {
  font-family: "Rubik", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.composeMail-icon {
  // padding-left: 7px !important ;
  // padding-right: 6px !important;
  width: 23px;
  height: 22px;
}

.composeMail-btn {
  padding: 9px;
  border-radius: 50%;
}

// .public-DraftEditor-content[contenteditable="true"] {
//   // min-height: 240px !important;
//   min-height: 180px !important;
// }

.show-modal-type {
  p {
    color: black !important;
  }
}

.check-delete-all {
  color: #d23b7d !important;
  fill: #d23b7d !important;
  /* For text color */
}

.announcement-delete-modal-container {
  font-family: "Rubik", sans-serif !important;
}

.e8k2yoa {
  margin: 0;
  padding: 4px !important;
  width: auto !important;
  height: auto !important;
  box-sizing: border-box;
  line-height: 1.2em;
  font-size: 2em !important;
  color: #686767 !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 6px !important;
  background-color: inherit !important;
  cursor: pointer;
}

.e8k2yoa:hover,
.e8k2yoa:active,
.e8k2yoa:focus {
  background: #d34a7c24 !important;
}

.e13wqaj6 {
  margin: 0;
  width: auto !important;
  height: auto !important;
  box-sizing: border-box;
  line-height: 1.2em;
  background-color: inherit !important;
  font-size: 2em !important;
  color: #686767 !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 6px !important;
  cursor: pointer;
  padding: 4px !important;
}

.e13wqaj6:hover,
.e13wqaj6:active,
.e13wqaj6:focus {
  background: #d34a7c24 !important;
}

.Dynamic-emoji {
  position: absolute;
  left: 195px;

  .e6nwac2 button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.announcement-body-desc {
  min-height: 230px;

  a {
    color: #007bff; // Set your desired color
    text-decoration: none; // Optionally remove underline

    &:hover {
      color: #0056b3; // Change color on hover
      text-decoration: underline; // Optional hover underline
    }
  }
}

.bc4rxid {
  background-color: inherit !important;
  border-radius: 0.375rem !important;
  padding: 0.25rem !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color: #757575;
}

.bc4rxid:hover {
  background-color: #d34a7c24 !important;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  top: 4px !important;
}

// Mobile and Tablets
@media (max-width: 1024px) {
  .singleAnnouncement--body {
    background-color: #f5f5f5;
    padding: 15px;
    min-height: calc(100vh - 450px);
    height: auto;
  }
  .active_ {
    padding-right: 0px !important;
  }
  .announcement-mailBody {
    padding: 0px;
  }
  .singleAnnouncement--body .receive-mail .profile {
    width: 45px;
    height: 45px;
    background-color: #d34a7c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: #fff;
  }
  .singleAnnouncement--body .receive-mail .email-lists h6 {
    color: #2f2f2f;
    font-size: 14px;
    max-width: 192px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .singleAnnouncement--body .receive-mail .email-lists p {
    color: #949494;
    font-size: 12px;
    max-width: 207px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .singleAnnouncement--body .receive-mail .profile {
    width: 38px;
    height: 38px;
    font-size: 16px;
  }
}
