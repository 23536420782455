.rejected-designation {
  color: red !important;
}

.Revised-Salary-Modal {
  .Increment-box {
    width: 90% !important;
  }
}

.template_container {
  .ant-spin-spinning {
    top: 0px !important;
    height: 100% !important;
  }
}
